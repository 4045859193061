const Joi = require('joi');

const envVarsSchema = Joi.object()
  .keys({
    NODE_ENV: Joi.string().valid('production', 'development', 'demo', 'test').required(),
    REACT_APP_BUSINESS_NAME: Joi.string().required().description('Business name is required'),
    REACT_APP_CLIENT_URL: Joi.string().allow('').default('localhost:3000'),
    REACT_APP_API_URL: Joi.string().allow('').default('').description('backend server url'),
    REACT_APP_LOGO_FILENAME: Joi.string().allow('').default('').description('logo url'),
    REACT_APP_STRIPE_PUBLIC_KEY: Joi.string().required().description('Stripe public key'),
    REACT_APP_STRIPE_ADMIN_KEY: Joi.string().description('Stripe admin key for indetifying prices').default("default"),
    REACT_APP_USERS_PER_PAGE: Joi.number().default(10).description('number of users per page in users table'),
    REACT_APP_PROD_PAYPAL_CLIENT_ID: Joi.string().required().description('Prod Paypal client id'),
    REACT_APP_TEST_PAYPAL_CLIENT_ID: Joi.string().required().description('Test Paypal client id'),
  })
  .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}

export const config = {
  businessName: envVars.REACT_APP_BUSINESS_NAME,
  logoFilename: envVars.REACT_APP_LOGO_FILENAME === '' ? false : envVars.REACT_APP_LOGO_FILENAME,
  clientURL: envVars.NODE_ENV === 'production' ? envVars.REACT_APP_CLIENT_URL : 'http://localhost:3000',
  api: {
    url: envVars.NODE_ENV === 'production' ? envVars.REACT_APP_API_URL : '',
  },
  stripe: {
    publicKey: envVars.REACT_APP_STRIPE_PUBLIC_KEY,
    adminKey: envVars.REACT_APP_STRIPE_ADMIN_KEY,
  },
  users: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  paypalClientId: envVars.NODE_ENV === 'production' ? envVars.REACT_APP_PROD_PAYPAL_CLIENT_ID : envVars.REACT_APP_TEST_PAYPAL_CLIENT_ID,
};
